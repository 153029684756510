import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './EventCard.module.css';
import routes from 'routes';
import { ReactComponent as CompetitionIcon } from 'assets/images/icon_competition.svg';
import { ReactComponent as TrainingIcon } from 'assets/images/icon_training.svg';
import ReadMoreText from 'components/common/ReadMoreText';

const EventCard = ({ event, clubId }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(routes.admin.events.url(clubId, event.meetID));
  };

  return (
    <div onClick={handleCardClick} className={styles.eventCard}>
      <div className={styles.eventCardImageWrapper}>
        <div className={styles.eventCardImage}>
          <img src={event.image} alt={event.meetName} />
        </div>
        <div
          className={`${styles.eventCardTagWrapper} ${event.meetType === 0 ? styles.eventCardTrainingTagWrapper : ''}`}
        >
          <div className={styles.eventCardTagImage}>
            {event.meetType === 0 ? <TrainingIcon /> : <CompetitionIcon />}
          </div>
          <div className={styles.eventCardTagText}>
            {event.meetType === 0 ? 'training' : 'competition'}
          </div>
        </div>
      </div>
      <div className={styles.eventCardBody}>
        <div className={styles.eventCardTitle}>{event.meetName}</div>
        <div className={styles.eventCardTime}>
          {event.date} <span>from {event.time}</span>
        </div>
        <ReadMoreText
          text={event.description}
          style={{
            fontSize: 14,
            color: '#313131',
            marginBottom: 9,
            lineHeight: 1.5,
          }}
          readMoreStyle={{
            fontSize: 16,
            fontWeight: 700,
            color: '#cf2c47',
            cursor: 'pointer',
            textDecoration: 'none',
          }}
        />
      </div>
    </div>
  );
};

EventCard.propTypes = {
  event: PropTypes.object,
  clubId: PropTypes.string,
};

export default EventCard;
