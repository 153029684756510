import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { getToken } from 'services/auth/tokenService';
import { decodeToken } from 'utils/auth';
import routes from 'routes';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const token = getToken();

  if (!token) {
    toast.error('You must be logged in.');
    return <Navigate to={routes.auth.login} />;
  }

  const decodedToken = decodeToken(token);
  if (!decodedToken) {
    toast.error('You must be logged in.');
    return <Navigate to={routes.auth.login} />;
  }

  const { userRole, exp } = decodedToken;

  const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
  if (exp < currentTime) {
    toast.error('Session expired. Please log in again.');
    return <Navigate to={routes.auth.login} />;
  }

  if (allowedRoles) {
    if (!allowedRoles.includes(userRole)) {
      toast.error('You do not have permission to view the page.');
      return <Navigate to={routes.client.home} />;
    }
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

export default ProtectedRoute;
