import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AdminDataTable.module.css';

import { ReactComponent as SearchIcon } from 'assets/images/icon_search.svg';
import { ReactComponent as FilterIcon } from 'assets/images/icon_filter.svg';
import { ReactComponent as SortIcon } from 'assets/images/icon_sort.svg';

const AdminDataTable = ({
  data = [],
  isAddedFeatures = true,
  columns,
  headStyle,
  rowStyle,
  tableStyle,
  searchInputPlaceholder,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const generatedFilters = {};
    columns.forEach((column) => {
      if (column.filterable) {
        generatedFilters[column.accessor] = [
          ...new Set(data.map((row) => row[column.accessor])),
        ];
      }
    });
    setFilters(generatedFilters);
  }, [data, columns]);

  const filteredData = data.filter((row) => {
    // Search filter
    const matchesSearch = columns.some((column) => {
      const cellValue = row[column.accessor];
      return (
        cellValue &&
        cellValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

    // Column filters
    const matchesFilters = Object.keys(filters).every((key) => {
      const filterValue = filters[key].selectedValue;
      return !filterValue || row[key] === filterValue;
    });

    return matchesSearch && matchesFilters;
  });

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { key, direction: 'asc' };
    });
  };

  const handleFilterChange = (columnKey, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [columnKey]: { ...prevFilters[columnKey], selectedValue: value },
    }));
  };

  return (
    <div className={styles.athletesTableWrapper}>
      <div className={styles.athletesTable} style={tableStyle}>
        {isAddedFeatures ? (
          <div className={styles.athletesTableHeader}>
            <div className={styles.inputWrapper}>
              <input
                type="text"
                placeholder={searchInputPlaceholder}
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <SearchIcon />
            </div>
            {Object.keys(filters).map((columnKey) => (
              <div key={columnKey} className={styles.filterWrapper}>
                <div className={styles.filterText}>
                  {columns.find((col) => col.accessor === columnKey).label}
                </div>
                <div className={styles.filterImage}>
                  <FilterIcon />
                </div>
                <select
                  onChange={(e) =>
                    handleFilterChange(columnKey, e.target.value)
                  }
                  value={filters[columnKey].selectedValue || ''}
                  className={styles.filterDropdown}
                >
                  <option value="">All</option>
                  {filters[columnKey].map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            ))}
            {/*<div className={styles.filterWrapper}>*/}
            {/*  <div className={styles.filterText}>Filter</div>*/}
            {/*  <div className={styles.filterImage}>*/}
            {/*    <FilterIcon />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={styles.sortWrapper}>*/}
            {/*  <div className={styles.sortText}>Sort</div>*/}
            {/*  <div className={styles.sortImage}>*/}
            {/*    <SortIcon />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        ) : null}
        <div className={styles.athletesRows}>
          <div className={styles.athletesRow} style={headStyle}>
            {columns.map((column, i) => (
              <div
                key={i}
                className={styles.headerCell}
                style={column.headerStyle}
                onClick={() => column.sortable && handleSort(column.accessor)}
              >
                {column.checkbox ? (
                  <div className={styles.headerCheckbox}></div>
                ) : null}
                {column.label}
                {column.sortable ? (
                  <div className={styles.headerSortImage}>
                    <SortIcon
                      style={{
                        cursor: 'pointer',
                        transform:
                          sortConfig.key === column.accessor
                            ? sortConfig.direction === 'asc'
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)'
                            : 'rotate(0deg)',
                        fill: '#000',
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          {sortedData.map((row, i) => (
            <div key={i} className={styles.athletesRow} style={rowStyle}>
              {columns.map((column, i) => (
                <div key={i} className={styles.childCell} style={column.style}>
                  {column.checkbox ? (
                    <div className={styles.bodyCheckbox}></div>
                  ) : null}
                  {row[column.accessor]}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AdminDataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  headStyle: PropTypes.object,
  rowStyle: PropTypes.object,
  tableStyle: PropTypes.object,
  searchInputPlaceholder: PropTypes.string,
  isAddedFeatures: PropTypes.bool,
};

export default AdminDataTable;
