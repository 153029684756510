export const dataTypeOptions = [
  { value: 'published', label: 'Published Results' },
  { value: 'pending', label: 'Pending Results' },
];

export const columns = [
  {
    accessor: 'athleteNumber',
    label: 'Club ID',
    headerStyle: {
      fontSize: 13,
      paddingLeft: 21,
      minWidth: 154,
      letterSpacing: -0.3,
    },
    style: {
      fontSize: 13,
      paddingLeft: 21,
      minWidth: 154,
    },
  },
  {
    accessor: 'athleteName',
    label: 'Name',
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'position',
    label: 'Position',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 5,
      minWidth: 58,
    },
    style: {
      paddingLeft: 22,
      minWidth: 58,
    },
  },
  {
    accessor: 'result',
    label: 'Result',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 5,
      minWidth: 58,
    },
    style: {
      paddingLeft: 22,
      minWidth: 58,
    },
  },
  {
    accessor: 'isPR',
    label: 'Is PR',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      paddingLeft: 22,
    },
  },
  {
    accessor: 'pr',
    label: 'PR',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      paddingLeft: 22,
    },
  },
  {
    accessor: 'status',
    label: 'Status',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      paddingLeft: 22,
    },
  },
];
