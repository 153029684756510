import { authApi } from 'api';

/**
 * Create a new club by sending a POST request to the '/Clubs' endpoint.
 *
 * @param {Object} clubData - The data of the club to be created.
 * @returns {Promise} - The response from the API.
 */
export const createClub = async (clubData) => {
  return await authApi.post('/Clubs', clubData);
};

/**
 * Fetch the list of all clubs by sending a GET request to the '/Clubs' endpoint.
 *
 * @returns {Promise} - The response from the API containing the list of clubs.
 */
export const getClubs = async () => {
  return await authApi.get('/Clubs');
};

/**
 * Fetch the list of meets for a specific club by sending a GET request to the '/meets/{clubId}' endpoint.
 * Optionally, you can pass the 'lastUpdated' parameter to filter meets after a specific time.
 *
 * @param {string} clubId - The ID of the club whose meets are to be fetched.
 * @param {string|null} lastUpdated - The optional timestamp to filter meets by their last update time.
 * @returns {Promise} - The response from the API containing the list of meets.
 */
export const getMeets = async (clubId, lastUpdated = null) => {
  if (!lastUpdated) {
    return await authApi.get(`/meets/${clubId}`);
  } else {
    return await authApi.get(`/meets/${clubId}?lastUpdated=${lastUpdated}`);
  }
};

/**
 * Fetch the list of recent meets for a specific club by sending a GET request to the '/meets/recent/{clubId}' endpoint.
 * Optionally, you can pass the 'lastUpdated' parameter to filter meets after a specific time.
 *
 * @param {string} clubId - The ID of the club whose recent meets are to be fetched.
 * @param {string|null} lastUpdated - The optional timestamp to filter meets by their last update time.
 * @returns {Promise} - The response from the API containing the list of recent meets.
 */
export const getRecentMeets = async (clubId, lastUpdated = null) => {
  if (!lastUpdated) {
    return await authApi.get(`/meets/recent/${clubId}`);
  } else {
    return await authApi.get(
      `/meets/recent/${clubId}?lastUpdated=${lastUpdated}`
    );
  }
};

/**
 * Fetch the list of upcoming meets for a specific club by sending a GET request to the '/meets/upcoming/{clubId}' endpoint.
 * Optionally, you can pass the 'lastUpdated' parameter to filter meets after a specific time.
 *
 * @param {string} clubId - The ID of the club whose upcoming meets are to be fetched.
 * @param {string|null} lastUpdated - The optional timestamp to filter meets by their last update time.
 * @returns {Promise} - The response from the API containing the list of upcoming meets.
 */
export const getUpcomingMeets = async (clubId, lastUpdated = null) => {
  if (!lastUpdated) {
    return await authApi.get(`/meets/upcoming/${clubId}`);
  } else {
    return await authApi.get(
      `/meets/upcoming/${clubId}?lastUpdated=${lastUpdated}`
    );
  }
};

/**
 * Fetch the list of members for a specific club by sending a GET request to the '/users/{clubId}/members' endpoint.
 *
 * @param {string} clubId - The ID of the club whose members are to be fetched.
 * @returns {Promise} - The response from the API containing the list of members.
 */
export const getMembers = async (clubId) => {
  return await authApi.get(`/users/${clubId}/members`);
};

/**
 * Fetch the details of a specific meet by sending a GET request to the '/meets/{clubId}/{meetId}' endpoint.
 *
 * @param {string} clubId - The ID of the club associated with the meet.
 * @param {string} meetId - The ID of the meet to be fetched.
 * @returns {Promise} - The response from the API containing the details of the specified meet.
 */
export const getMeetById = async (clubId, meetId) => {
  return await authApi.get(`/meets/${clubId}/${meetId}`);
};

/**
 * Fetches eligible athletes for a specific club, optionally filtered by a last updated timestamp.
 *
 * This function retrieves a list of eligible athletes for a given club. If a `lastUpdated` timestamp
 * is provided, it will only return athletes who have been updated since that timestamp.
 *
 * @param {string} clubId - The unique identifier of the club.
 * @param {string|null} lastUpdated - An optional timestamp to filter athletes by their last update date (format: YYYY-MM-DDTHH:mm:ss).
 * @returns {Promise<Object>} - A Promise that resolves to the response from the API containing eligible athletes.
 */
export const getEligibleAthletes = async (clubId, lastUpdated = null) => {
  if (!lastUpdated) {
    return await authApi.get(`/users/meet-athletes/${clubId}`);
  } else {
    return await authApi.get(
      `/users/meet-athletes/${clubId}?lastUpdated=${lastUpdated}`
    );
  }
};
