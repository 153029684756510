import authApi from './index';

/**
 * Fetch the result highlights for a specific club by sending a GET request to the '/results/get-result-highlights' endpoint.
 * It takes the club ID and the number of highlights to fetch (default is 8).
 *
 * @param {string} clubId - The ID of the club whose result highlights are to be fetched.
 * @param {number} numberOfHighlights - The number of result highlights to fetch (default is 8).
 * @returns {Promise} - The response from the API containing the result highlights.
 */
export const getResultHighlights = async (clubId, numberOfHighlights = 8) => {
  return await authApi.get(
    `/results/get-result-highlights?clubId=${clubId}&numberOfHighlights=${numberOfHighlights}`
  );
};

/**
 * Fetch the list of completed events for a specific club and meet by sending a GET request to the '/results/get-events' endpoint.
 * Optionally, you can pass the 'lastUpdated' parameter to filter events updated after a specific time.
 *
 * @param {string} clubId - The ID of the club whose completed events are to be fetched.
 * @param {string} meetId - The ID of the meet whose completed events are to be fetched.
 * @param {string|null} lastUpdated - The optional timestamp to filter events by their last update time.
 * @returns {Promise} - The response from the API containing the list of completed events.
 */
export const getCompletedEvents = async (
  clubId,
  meetId,
  lastUpdated = null
) => {
  if (!lastUpdated) {
    return await authApi.get(
      `/results/get-events?clubId=${clubId}&meetId=${meetId}`
    );
  } else {
    return await authApi.get(
      `/results/get-events?clubId=${clubId}&meetId=${meetId}&lastUpdated=${lastUpdated}`
    );
  }
};

/**
 * Fetch the results for specific events by sending a POST request to the '/results/get-event-results' endpoint.
 * The request includes a list of event IDs, the club ID, and an optional timestamp to filter results updated after a specific time.
 *
 * @param {Array<string>} eventIds - An array of event IDs for which results are to be fetched.
 * @param {string} clubId - The ID of the club associated with these events.
 * @param {string|null} since - An optional timestamp to filter results by their last update time.
 * @returns {Promise} - The response from the API containing the results for the specified events.
 */
export const getResultsForEvents = async (eventIds, clubId, since = null) => {
  return await authApi.post('/results/get-event-results', {
    eventIds,
    clubId,
    since,
  });
};

/**
 * Fetches details of selected events for a specific club and meet.
 *
 * This function retrieves information about specific events for a given club and meet.
 * Optionally, it filters the results based on a `lastUpdated` timestamp.
 *
 * @param {string} clubId - The unique identifier of the club.
 * @param {string} meetId - The unique identifier of the meet.
 * @param {Array<string>} eventIds - An array of event IDs to fetch details for.
 * @param {string|null} lastUpdated - An optional timestamp to filter events by their last update date (format: YYYY-MM-DDTHH:mm:ss).
 * @returns {Promise<Object>} - A Promise that resolves to the API response containing details of the selected events.
 */
export const getSelectedEvents = async (
  clubId,
  meetId,
  eventIds,
  lastUpdated = null
) => {
  return await authApi.post(`/results/get-selected-events`, {
    clubId,
    meetId,
    eventIds,
    lastUpdated,
  });
};
