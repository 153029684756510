import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { logout } from 'api/authApi';

import styles from './ProfileMenu.module.css';
import SimpleProfile from 'components/common/SimpleProfile';
import routes from '../../../routes';

const ProfileMenu = ({ user }) => {
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = React.useState(false);
  return (
    <div
      className={`${styles.profileMenu} ${showMenu ? styles.profileMenuActive : ''}`}
    >
      <SimpleProfile user={user} onClick={() => setShowMenu(!showMenu)} />
      <div className={styles.menuContainer}>
        <div>
          <div
            className={styles.menuItem}
            onClick={() => {
              navigate(routes.admin.profile);
              setShowMenu(false);
            }}
          >
            My Profile
          </div>
          <div className={styles.menuItem}>Account Settings</div>
          <div
            onClick={() => {
              logout().then(() => navigate(routes.client.home));
            }}
            className={`${styles.menuItem} ${styles.menuItemActive}`}
          >
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

ProfileMenu.propTypes = {
  user: PropTypes.object,
};

export default ProfileMenu;
