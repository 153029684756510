import TableViewIcon from 'assets/images/icon_table_view.png';
import CardIcon from 'assets/images/icon_card.svg';

export const dataTypeOptions = [
  { value: 'upcoming', label: 'Upcoming' },
  { value: 'past', label: 'Past' },
  { value: 'highlights', label: 'Highlights' },
];

export const viewTypeOptions = [
  { value: 'table', label: 'Table View', icon: TableViewIcon },
  { value: 'card', label: 'Card View', icon: CardIcon },
];

export const columns = [
  {
    accessor: 'date',
    label: 'Date',
    checkbox: true,
  },
  {
    accessor: 'time',
    label: 'Time',
  },
  {
    accessor: 'title',
    label: 'Competition Title',
  },
  {
    accessor: 'venue',
    label: 'Venue',
  },
  {
    accessor: 'teams',
    label: 'Team (s)',
  },
  {
    accessor: 'disciplines',
    label: 'Disciplines',
  },
];
