import React from 'react';
import { Routes, Route } from 'react-router-dom';

import routes from './routes';
import ProtectedRoute from './ProtectedRoute';

import AuthLayout from 'layouts/AuthLayout';
import AdminLayout from 'layouts/AdminLayout';
import FormWithFooterLayout from 'layouts/FormWithFooterLayout';

/* Auth Pages */
import Login from 'pages/auth/Login';
// import OwnerDetail from 'pages/auth/OwnerDetail';
// import OwnerPlan from 'pages/auth/OwnerPlan';
// import OwnerBilling from 'pages/auth/OwnerBilling';
// import SetUpClub from 'pages/auth/SetUpClub';
// import FindClubs from 'pages/auth/FindClubs';
// import VerifyEmail from 'pages/auth/VerifyEmail';

/* Client Pages */

/* Admin Page*/
import Dashboard from 'pages/admin/Dashboard';
import AddUser from 'pages/admin/AddUser';
import Athletes from 'pages/admin/Athletes';
import Competitions from 'pages/admin/Competitions';
import MyProfile from 'pages/admin/MyProfile';
import Events from 'pages/admin/Events';
import Results from 'pages/admin/Results';

import NotFound from 'pages/NotFound';

const RouteConfig = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={routes.client.home} element={<Login />} />
        <Route path={routes.auth.login} element={<Login />} />
        {/*<Route path={routes.auth.general} element={<OwnerDetail />} />*/}
        {/*<Route path={routes.auth.plan} element={<OwnerPlan />} />*/}
        {/*<Route path={routes.auth.billing} element={<OwnerBilling />} />*/}
        {/*<Route path={routes.auth.findClubs} element={<FindClubs />} />*/}
        {/*<Route path={routes.auth.verifyEmail} element={<VerifyEmail />} />*/}
        {/*<Route path={routes.auth.setupClub} element={<SetUpClub />} />*/}
      </Route>

      <Route element={<FormWithFooterLayout />}>
        <Route
          path={routes.admin.addUser.path}
          element={
            <ProtectedRoute allowedRoles={['AccountOwner']}>
              <AddUser />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route element={<AdminLayout />}>
        <Route
          path={routes.admin.dashboard}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.admin.athletes}
          element={
            <ProtectedRoute allowedRoles={['AccountOwner']}>
              <Athletes />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.admin.competitions}
          element={
            <ProtectedRoute allowedRoles={['AccountOwner']}>
              <Competitions />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.admin.events.path}
          element={
            <ProtectedRoute allowedRoles={['AccountOwner']}>
              <Events />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.admin.results.path}
          element={
            <ProtectedRoute allowedRoles={['AccountOwner']}>
              <Results />
            </ProtectedRoute>
          }
        />
        <Route path={routes.admin.profile} element={<MyProfile />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteConfig;
