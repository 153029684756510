import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import styles from './Events.module.css';

import routes from 'routes';
import { columns } from 'config/admin/event';
import { convertUTCToLocalDateTime } from 'utils/time';
import { getCompletedEvents } from 'api/resultApi';
import { getMeetById } from 'api/clubApi';

import MetaTags from 'components/common/MetaTags';
import AdminPageHeader from 'components/admin/AdminPageHeader';
import AdminTablePageLayout from 'components/admin/AdminTablePageLayout';
import AdminDataTable from 'components/admin/AdminDataTable';

const Events = () => {
  const { clubId, meetId } = useParams();

  const [fetchingEvents, setFetchingEvents] = useState(true);
  const [fetchingMeet, setFetchingMeet] = useState(true);

  const [meet, setMeet] = useState(null);
  const [events, setEvents] = useState([]);

  const fetchEvents = useCallback(async () => {
    try {
      const response = await getCompletedEvents(clubId, meetId);

      const events = [];
      response.data.forEach((event) => {
        events.push({
          ...event,
          date: `${convertUTCToLocalDateTime(event.timestamp).date} ${convertUTCToLocalDateTime(event.timestamp).time}`,
          manage: (
            <Link
              to={routes.admin.results.url(clubId, meetId, event.eventID)}
              className={styles.viewButton}
            >
              Results
            </Link>
          ),
        });
      });
      setEvents(events);
    } catch (err) {
      console.error('[Events] Fetch events error: ', err);
    }
  }, [clubId, meetId]);
  const fetchMeet = useCallback(async () => {
    try {
      const response = await getMeetById(clubId, meetId);
      console.log(response.data);

      setMeet(response.data);
    } catch (err) {
      console.log('[Events] Fetch Meets error: ', err);
    }
  }, [clubId, meetId]);

  useEffect(() => {
    fetchEvents().then(() => {
      setFetchingEvents(false);
    });
  }, [fetchEvents]);

  useEffect(() => {
    fetchMeet().then(() => {
      setFetchingMeet(false);
    });
  }, [fetchMeet]);
  return (
    <>
      <MetaTags title="SplitFast | Events" />
      <AdminTablePageLayout loading={fetchingEvents || fetchingMeet}>
        <AdminPageHeader
          title={
            meet
              ? `Events for ${meet?.meetName} - ${convertUTCToLocalDateTime(meet?.meetDate, 'short').date}`
              : 'Events'
          }
          showNumber={false}
          number={events.length}
          name="Event"
          buttonLabel="+ Add an Event"
          handleButton={() => {
            console.log('add an event');
          }}
        />
        {events.length ? (
          <AdminDataTable
            columns={columns}
            data={events}
            searchInputPlaceholder="Search Events"
            headStyle={{
              gridTemplateColumns: '6fr 4fr 4fr 4fr 3fr 2fr',
            }}
            rowStyle={{
              gridTemplateColumns: '6fr 4fr 4fr 4fr 3fr 2fr',
            }}
          />
        ) : null}
      </AdminTablePageLayout>
    </>
  );
};

export default Events;
