import React from 'react';
import PropTypes from 'prop-types';
import styles from './SimpleClub.module.css';
import clubImage from 'assets/images/turboTrackIcon.png';

const SimpleClub = ({ club }) => {
  return (
    <div className={styles.clubContainer}>
      <div className={styles.clubImage}>
        <img src={club?.image || clubImage} alt="club" />
      </div>
      <div className={styles.clubName}>{club?.clubName}</div>
    </div>
  );
};

SimpleClub.propTypes = {
  club: PropTypes.object,
};

export default SimpleClub;
