import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClubs } from 'api/clubApi';
import { toast } from 'react-toastify';
import routes from 'routes';
import { decodeToken } from 'utils/auth';
import { getToken, setUserRole } from 'services/auth/tokenService';

const LoginRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    async function loadClubs() {
      try {
        const response = await getClubs();

        const clubs = response.data;

        const token = getToken();
        const userRole = decodeToken(token).userRole;
        setUserRole(userRole);

        if (clubs.length > 0) {
          navigate(routes.admin.dashboard);
        } else {
          if (userRole === 'AccountOwner') {
            navigate(routes.auth.setupClub);
          } else {
            navigate(routes.auth.findClubs);
          }
        }
      } catch (err) {
        toast.error(err.message);
        navigate(routes.auth.login);
      }
    }

    loadClubs();
  }, [navigate]);

  return null; // No UI needed; redirecting based on logic
};

export default LoginRedirect;
