const routes = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    general: '/auth/general',
    plan: '/auth/plan',
    billing: '/auth/billing',
    setupClub: '/auth/setup-club',
    findClubs: '/auth/find-clubs',
    verifyEmail: '/auth/verify-email',
  },
  client: {
    home: '/',
  },
  admin: {
    dashboard: '/dashboard',
    addUser: {
      path: '/clubs/:id/users/add',
      url: (id) => {
        return `/clubs/${id}/users/add`;
      },
    },
    athletes: '/athletes',
    events: {
      path: '/clubs/:clubId/meets/:meetId/events',
      url: (clubId, meetId) => {
        return `/clubs/${clubId}/meets/${meetId}/events`;
      },
    },
    results: {
      path: '/clubs/:clubId/meets/:meetId/events/:eventId/results',
      url: (clubId, meetId, eventId) => {
        return `/clubs/${clubId}/meets/${meetId}/events/${eventId}/results`;
      },
    },
    competitions: '/competitions',
    notification: '/notifications',
    profile: '/profile',
  },
};

export default routes;
