const TOKEN_KEY = 'jwt_token';
const USER_ROLE_KEY = 'user_role';
const USER_INFO_KEY = 'user_info';

export const setToken = (storage, token) => {
  storage.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
};

export const setUserRole = (userRole) => {
  localStorage.setItem(USER_ROLE_KEY, userRole);
};

export const getUserRole = () => {
  return (
    localStorage.getItem(USER_ROLE_KEY) || sessionStorage.getItem(USER_ROLE_KEY)
  );
};

export const removeUserRole = () => {
  localStorage.removeItem(USER_ROLE_KEY);
  sessionStorage.removeItem(USER_ROLE_KEY);
};

export const setUserInfo = (storage, userInfo) => {
  storage.setItem(USER_INFO_KEY, userInfo);
};

export const getUserInfo = () => {
  return (
    localStorage.getItem(USER_INFO_KEY) || sessionStorage.getItem(USER_INFO_KEY)
  );
};

export const removeUserInfo = () => {
  localStorage.removeItem(USER_INFO_KEY);
  sessionStorage.removeItem(USER_INFO_KEY);
};
