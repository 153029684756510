export const competitionTableRowLimit = 3;
export const trainingSessionTableRowLimit = 3;
export const upcomingTableRowLimit = 4;
export const resultHighlightsTableRowLimit = 8;

export const competitionTableColumns = [
  { accessor: 'date' },
  { accessor: 'time' },
  { accessor: 'meetName', style: { fontSize: '13px' } },
  { accessor: 'location', style: { fontWeight: '400' } },
  {
    accessor: 'buttons',
    style: {
      fontSize: '11px',
      backgroundColor: '#cf2c47',
      borderRadius: '3px',
      color: '#fff',
      padding: '5px 15px 2px',
      fontWeight: '600',
    },
  },
];

export const trainingSessionTableColumns = [
  { accessor: 'date' },
  { accessor: 'time' },
  { accessor: 'meetName', style: { fontSize: '13px' } },
  { accessor: 'location', style: { fontWeight: '400' } },
  {
    accessor: 'buttons',
    style: {
      fontSize: '11px',
      backgroundColor: '#cf2c47',
      borderRadius: '3px',
      color: '#fff',
      padding: '5px 15px 2px',
      fontWeight: '600',
    },
  },
];

export const highlightTableColumns = [
  { accessor: 'date' },
  { accessor: 'eventType' },
  { accessor: 'gender' },
  { accessor: 'ageGroup' },
  { accessor: 'athleteName' },
  {
    accessor: 'resultValue',
    style: {
      textAlign: 'right',
    },
  },
  {
    accessor: 'isPR',
  },
  {
    accessor: 'url',
    style: {
      fontSize: 11,
      backgroundColor: '#cf2c47',
      borderRadius: 3,
      color: '#fff',
      padding: '5px 15px 2px',
      fontWeight: '600',
      textAlign: 'center',
      width: 'fit-content',
    },
  },
];
