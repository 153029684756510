/**
 * Converts a UTC time string to a specific time zone and formats it according to the given options.
 *
 * @param {string} utcTimeString - The UTC time string to be converted, in the format 'YYYY-MM-DDTHH:mm:ss'.
 * @param {string} timeZone - The time zone to which the UTC time should be converted (e.g., 'America/New_York').
 * @param {string} [monthType='2-digit'] - The format for the month. Can be '2-digit' for numeric month (e.g., '07') or 'short' for abbreviated month name (e.g., 'Jul').
 * @returns {string} - The formatted date string in the specified time zone, in the format 'MM/DD/YYYY HH:MM:SS' or 'DD MMM YYYY HH:MM:SS' depending on the `monthType`.
 */
export const convertUTCToTimeZone = (
  utcTimeString,
  timeZone,
  monthType = '2-digit'
) => {
  const [year, month, day, hour, minute, second] = utcTimeString
    .split(/[-T:.]/)
    .map(Number);
  const utcDate = new Date(
    Date.UTC(year, month - 1, day, hour, minute, second)
  );

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    year: 'numeric',
    month: monthType,
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hourCycle: 'h23',
  }).format(utcDate);

  if (monthType === 'short') {
    // If monthType is 'short', format as "Jul 11 2024"
    const [datePart, timePart] = formattedDate.split(', ');
    const [monthPart, dayPart] = datePart.split(' ');
    return `${dayPart} ${monthPart} ${year}, ${timePart}`;
  }

  return formattedDate;
};

/**
 * Gets the user's current time zone.
 *
 * This function uses the `Intl.DateTimeFormat` API to retrieve the IANA time zone
 * identifier (e.g., "America/New_York" or "Asia/Tokyo") of the user's system.
 *
 * @returns {string} - The user's current time zone as a string.
 */
export const getUserTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * Converts a UTC time string to the user's local date and time.
 *
 * This function takes a UTC time string, converts it to the user's local
 * date and time based on the user's time zone, and returns an object with
 * separate `date` and `time` strings. The `monthType` parameter allows
 * flexibility in formatting the month (either as '2-digit' or 'short').
 *
 * @param {string} utcTimeString - The UTC time string to convert (e.g., "2024-11-07T03:00:00").
 * @param {string} [monthType='2-digit'] - The format for the month. Can be '2-digit' for numeric month (e.g., '07') or 'short' for abbreviated month name (e.g., 'Jul').
 * @returns {Object} - An object containing `date` and `time` properties, formatted as strings.
 *   - `date`: The local date in the format 'MM/DD/YYYY' or 'DD MMM YYYY'.
 *   - `time`: The local time in the format 'HH:MM:SS'.
 */
export const convertUTCToLocalDateTime = (
  utcTimeString,
  monthType = '2-digit'
) => {
  if (!utcTimeString) return { date: '', time: '' };
  const localDateTime = convertUTCToTimeZone(
    utcTimeString,
    getUserTimeZone(),
    monthType
  );

  return {
    date: localDateTime.split(', ')[0],
    time: localDateTime.split(', ')[1],
  };
};
