import React from 'react';
import routes from 'routes';

import { ReactComponent as HomeIcon } from 'assets/images/icon_home.svg';
import { ReactComponent as AthleteIcon } from 'assets/images/icon_athlete.svg';
import { ReactComponent as TeamIcon } from 'assets/images/icon_team.svg';
import { ReactComponent as CompetitionIcon } from 'assets/images/icon_competition.svg';
import { ReactComponent as TrainingIcon } from 'assets/images/icon_training.svg';
import { ReactComponent as UserIcon } from 'assets/images/icon_user_setting.svg';
import { ReactComponent as SettingIcon } from 'assets/images/icon_settings.svg';

export const links = [
  { name: 'Club Dashboard', url: routes.admin.dashboard, icon: <HomeIcon /> },
  {
    name: 'Competitions',
    url: routes.admin.competitions,
    icon: <CompetitionIcon />,
  },
  {
    name: 'Training Sessions',
    url: routes.admin.notification,
    icon: <TrainingIcon />,
  },
  { name: 'Athletes', url: routes.admin.athletes, icon: <AthleteIcon /> },
  { name: 'Teams', url: routes.admin.notification, icon: <TeamIcon /> },
  { name: 'Members', url: routes.admin.notification, icon: <UserIcon /> },
  {
    name: 'Club Settings',
    url: routes.admin.notification,
    icon: <SettingIcon />,
  },
];
