import React, { useState } from 'react';
import { toast } from 'react-toastify';

import styles from './Login.module.css';
import routes from 'routes';

import { login } from 'api/authApi';
import { setToken, setUserInfo } from 'services/auth/tokenService';

// import AuthTab from 'components/auth/AuthTab';

// import googleIcon from 'assets/images/Google_Icon.png';
// import appleIcon from 'assets/images/Apple_Icon.png';

import CustomInput from 'components/common/CustomInput/CustomInput';
// import IconTextButton from 'components/common/IconTextButton';
import CustomButton from 'components/common/CustomButton';
// import CircleText from 'components/common/CircleText';
import TextLink from 'components/common/TextLink';
import LoginRedirect from 'components/navigation/LoginRedirect';
import CustomCheckbox from 'components/common/CustomCheckbox';
import MetaTags from 'components/common/MetaTags';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = 'Email address is invalid';
    }
    if (!formValues.password) {
      newErrors.password = 'Password is required';
    }

    return newErrors;
  };

  const clickLoginButton = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      try {
        const response = await login(formValues);
        const token = response.data.token;

        const storage = rememberMe ? localStorage : sessionStorage;
        setToken(storage, token);
        // setUserRole(storage, JSON.stringify(response.data.roles));
        setUserInfo(
          storage,
          JSON.stringify({
            firstName: response.data.firstName,
            lastName: response.data.lastName,
          })
        );

        toast.success('Login successfully.');
        setIsLogin(true);
      } catch (e) {
        console.log('error: ', e);
        toast.error(e.response.data);
      } finally {
        setLoading(false);
      }
    }
  };

  if (isLogin) {
    return <LoginRedirect />;
  }

  return (
    <>
      <MetaTags title="SplitFast | Login" />
      {/*<AuthTab />*/}
      <div className={styles.loginContent}>
        <div className={styles.loginInputWrappers}>
          <CustomInput
            label="Email Address"
            type="text"
            name="email"
            placeholder="myemail@gmail.com"
            error={errors.email}
            onChange={handleInputChange}
          />
          <CustomInput
            label="Password"
            type="password"
            name="password"
            placeholder="Type password"
            error={errors.password}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.lrmForgotP}>
          <CustomCheckbox
            label="Remember Me"
            onChange={() => setRememberMe(!rememberMe)}
            checked={rememberMe}
            style={{ margin: 0 }}
            labelStyle={{ fontWeight: 'normal' }}
          />
          <div className={styles.forgotPassword}>Forgot Password?</div>
        </div>
        <CustomButton disabled={loading} onClick={clickLoginButton}>
          Log in
        </CustomButton>
        <TextLink
          text="Don't have an account?"
          link={routes.auth.login}
          linkText="Register Now"
        />
        {/*<CircleText>or</CircleText>*/}
        {/*<IconTextButton*/}
        {/*  text="Continue with Google"*/}
        {/*  icon={googleIcon}*/}
        {/*  borderColor="#e1edf3"*/}
        {/*/>*/}
        {/*<IconTextButton*/}
        {/*  text="Continue with Apple"*/}
        {/*  icon={appleIcon}*/}
        {/*  textColor="#fff"*/}
        {/*  bgColor="#101010"*/}
        {/*  borderColor="#101010"*/}
        {/*/>*/}
      </div>
    </>
  );
};

export default Login;
