export const GENDER_OPTIONS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const USER_PERMISSIONS = [
  {
    value: 'admin',
    label: 'Administrator',
    text: 'An administrator can view content, but cannot edit anything aside from profiles',
  },
  {
    value: 'coach',
    label: 'Coach',
    text: 'A coach can view content, but cannot edit anything aside from profiles',
  },
  {
    value: 'member',
    label: 'Member',
    text: 'A member can view content, but cannot edit anything aside from profiles',
  },
];
