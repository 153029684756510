import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  dataTypeOptions,
  viewTypeOptions,
  columns,
} from 'config/admin/competition';
import { getUpcomingMeets } from 'api/clubApi';
import { convertUTCToLocalDateTime } from 'utils/time';

import MetaTags from 'components/common/MetaTags';
import AdminTablePageLayout from 'components/admin/AdminTablePageLayout';
import AdminPageHeader from 'components/admin/AdminPageHeader';
import AdminPageOption from 'components/admin/AdminPageOption';
import AdminDataTable from 'components/admin/AdminDataTable';

import upcomingEventImage1 from 'assets/images/uifb.png';
import upcomingEventImage3 from 'assets/images/uijh.png';
import upcomingEventImage4 from 'assets/images/uiqw.png';
import upcomingEventImage2 from 'assets/images/uifd.png';

const Competitions = () => {
  const { selectedClub } = useSelector((state) => state.user || {});

  const [fetchingUpcomingMeets, setFetchingUpcomingMeets] = useState(true);
  const [upcomingMeets, setUpcomingMeets] = useState([]);

  const [optionValues, setOptionValues] = useState({
    dataType: 'upcoming',
    viewType: 'table',
  });

  const handleOptionChange = (name, value) => {
    setOptionValues({ ...optionValues, [name]: value });
  };

  const fetchUpcomingMeets = useCallback(async () => {
    try {
      if (selectedClub) {
        const response = await getUpcomingMeets(selectedClub?.clubID || '');

        const upcomingMeets = [];
        response.data.forEach((meet, index) => {
          let image;
          switch (index % 4) {
            case 1:
              image = upcomingEventImage1;
              break;

            case 2:
              image = upcomingEventImage3;
              break;

            case 3:
              image = upcomingEventImage4;
              break;

            default:
              image = upcomingEventImage2;
          }
          upcomingMeets.push({
            ...meet,
            date: convertUTCToLocalDateTime(meet.meetDate).date,
            time: convertUTCToLocalDateTime(meet.meetDate).time,
            image,
          });
          setUpcomingMeets(upcomingMeets);
        });
      }
    } catch (err) {
      console.log('[Dashboard] Fetch Upcoming Meets error: ', err);
    }
  }, [selectedClub]);

  useEffect(() => {
    fetchUpcomingMeets().then(() => {
      setFetchingUpcomingMeets(false);
    });
  }, [fetchUpcomingMeets]);

  return (
    <>
      <MetaTags title="SplitFast | Competitions" />
      <AdminTablePageLayout loading={fetchingUpcomingMeets}>
        <AdminPageHeader
          number={0}
          name="Competition"
          buttonLabel="+ Add a Competition"
        />
        <AdminPageOption
          dataTypeOptions={dataTypeOptions}
          viewTypeOptions={viewTypeOptions}
          optionValues={optionValues}
          handleOptionChange={handleOptionChange}
        />
        {upcomingMeets.length ? (
          <AdminDataTable
            columns={columns}
            data={upcomingMeets}
            searchInputPlaceholder="Search Competitions"
          />
        ) : null}
      </AdminTablePageLayout>
    </>
  );
};

export default Competitions;
