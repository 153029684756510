import { defaultApi } from 'api';
import {
  removeToken,
  removeUserInfo,
  removeUserRole,
} from 'services/auth/tokenService';

export const createSubscriptionAccount = async (userData) => {
  return (await defaultApi.post('/Auth/create-account', userData)).data;
};

export const createMemberAccount = async (userData) => {
  return (await defaultApi.post('/Auth/register', userData)).data;
};

export const verifyEmail = async (email, code) => {
  return await defaultApi.post('/Auth/validate-email', {
    email,
    code,
  });
};

export const login = async (credential) => {
  return await defaultApi.post('/Auth/login', credential);
};

export const logout = async () => {
  removeToken();
  removeUserRole();
  removeUserInfo();
};
