export const columns = [
  {
    accessor: 'clubAthleteNumber',
    label: 'Club ID',
    sortable: true,
    headerStyle: {
      fontSize: 13,
      paddingLeft: 21,
      minWidth: 154,
      letterSpacing: -0.3,
    },
    style: {
      fontSize: 13,
      paddingLeft: 21,
      minWidth: 154,
    },
  },
  {
    accessor: 'userName',
    label: 'Athlete Name',
    checkbox: false,
    sortable: true,
    headerStyle: {
      paddingLeft: 20,
      paddingRight: 25,
      justifyContent: 'space-between',
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'ageGroup',
    label: 'Age',
    checkbox: false,
    sortable: false,
    filterable: true,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 5,
      minWidth: 58,
    },
    style: {
      paddingLeft: 22,
      minWidth: 58,
    },
  },
  {
    accessor: 'gender',
    label: 'Gender',
    checkbox: false,
    sortable: false,
    filterable: true,
    headerStyle: {
      paddingLeft: 22,
      paddingRight: 10,
    },
    style: {
      paddingLeft: 22,
    },
  },
  {
    accessor: 'teams',
    label: 'Team(s)',
    checkbox: false,
    sortable: false,
    headerStyle: {
      paddingLeft: 20,
    },
    style: {
      paddingLeft: 20,
    },
  },
  {
    accessor: 'participationStatus',
    label: 'Participation Status',
    checkbox: false,
    sortable: false,
    filterable: true,
    headerStyle: {
      fontSize: 13,
      paddingLeft: 20,
      minWidth: 137,
      letterSpacing: -0.3,
    },
    style: {
      paddingLeft: 20,
      minWidth: 137,
    },
  },
  {
    accessor: 'manage',
    checkbox: false,
    sortable: false,
    style: {
      fontSize: 12,
      color: '#cf2c47',
      cursor: 'pointer',
      padding: '0 25px',
    },
  },
];
