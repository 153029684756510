import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Results.module.css';
import routes from 'routes';
import { getResultsForEvents, getSelectedEvents } from 'api/resultApi';
import { getMeetById } from 'api/clubApi';
import { columns } from 'config/admin/result';
import { convertUTCToLocalDateTime } from 'utils/time';

import MetaTags from 'components/common/MetaTags';
import AdminPageHeader from 'components/admin/AdminPageHeader';
import AdminTablePageLayout from 'components/admin/AdminTablePageLayout';
import AdminDataTable from 'components/admin/AdminDataTable';
import { dataTypeOptions } from 'config/admin/result';
import AdminPageOption from 'components/admin/AdminPageOption';
import { defaultButtonStyle } from '../../../components/common/CustomButton';

const Results = () => {
  const navigate = useNavigate();
  const { clubId, eventId, meetId } = useParams();

  const [fetchingResults, setFetchingResults] = useState(true);
  const [fetchingEvent, setFetchingEvent] = useState(true);
  const [fetchingMeet, setFetchingMeet] = useState(true);

  const [meet, setMeet] = useState(null);
  const [event, setEvent] = useState(null);
  const [results, setResults] = useState([]);

  const fetchResults = useCallback(async () => {
    try {
      const response = await getResultsForEvents([eventId], clubId);
      console.log(response.data);
      const results = [];
      response.data.forEach((result) => {
        results.push({
          ...result,
          date: `${convertUTCToLocalDateTime(result.timestamp || '').date} ${convertUTCToLocalDateTime(result.timestamp || '').time}`,
          athleteName: `${result.athleteFirstName} ${result.athleteLastName}`,
          result: result.resultValue,
          isPR: 'No',
        });
      });
      setResults(results);
    } catch (err) {
      console.error('[Results] Fetching results Error: ', err);
    }
  }, [clubId, eventId]);
  const fetchEvent = useCallback(async () => {
    try {
      const response = await getSelectedEvents(clubId, meetId, [eventId]);
      console.log(response.data);

      setEvent(response.data[0]);
    } catch (err) {
      console.log('[Results] Fetch Event error: ', err);
    }
  }, [clubId, meetId, eventId]);
  const fetchMeet = useCallback(async () => {
    try {
      const response = await getMeetById(clubId, meetId);
      console.log(response.data);

      setMeet(response.data);
    } catch (err) {
      console.log('[Results] Fetch Meets error: ', err);
    }
  }, [clubId, meetId]);

  const firstResult = results.find((result) => result.position === 1);

  useEffect(() => {
    fetchResults().then(() => {
      setFetchingResults(false);
    });
  }, [fetchResults]);

  useEffect(() => {
    fetchEvent().then(() => {
      setFetchingEvent(false);
    });
  }, [fetchEvent]);

  useEffect(() => {
    fetchMeet().then(() => {
      setFetchingMeet(false);
    });
  }, [fetchMeet]);

  const [optionValues, setOptionValues] = useState({
    dataType: 'published',
  });

  const handleOptionChange = (name, value) => {
    setOptionValues({ ...optionValues, [name]: value });
  };

  return (
    <>
      <MetaTags title="SplitFast | Results" />
      <AdminTablePageLayout
        loading={fetchingMeet || fetchingEvent || fetchingResults}
      >
        <AdminPageHeader
          title={
            event
              ? `Results for ${event.gender} ${event.ageGroup} ${event.eventType} ${event.roundType}`
              : 'Results'
          }
          showNumber={false}
          numberText={`${results.length} Published, 0 Pending`}
          name="Result"
          buttonLabel={
            meet
              ? `${meet?.meetName} - ${convertUTCToLocalDateTime(meet?.meetDate || '', 'short').date}`
              : 'Go to event'
          }
          buttonStyle={{
            color: '#24282a',
            fontWeight: 600,
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            height: 'unset',
          }}
          handleButton={() => {
            navigate(routes.admin.events.url(clubId, meetId));
          }}
          style={{
            marginBottom: 0,
          }}
        />
        <div className={styles.subtitle}>
          Club record: {firstResult?.athleteFirstName}{' '}
          {firstResult?.athleteLastName} {firstResult?.resultValue} -{' '}
          {convertUTCToLocalDateTime(meet?.meetDate, 'short').date}
        </div>
        <AdminPageOption
          dataTypeOptions={dataTypeOptions}
          hasButton={true}
          optionValues={optionValues}
          handleOptionChange={handleOptionChange}
          buttonLabel="Edit Results"
          buttonStyle={{
            ...defaultButtonStyle,
            marginBottom: 0,
            height: 'unset',
            padding: '10px 20px',
          }}
          handleButton={() => console.log('edit results')}
        />
        {results.length ? (
          <AdminDataTable
            isAddedFeatures={false}
            columns={columns}
            data={results}
            searchInputPlaceholder="Search Events"
            headStyle={{
              gridTemplateColumns: '2fr 4fr 3fr 3fr 3fr 3fr 4fr',
              background: '#f1f1f2',
            }}
            rowStyle={{
              gridTemplateColumns: '2fr 4fr 3fr 3fr 3fr 3fr 4fr',
            }}
          />
        ) : null}
      </AdminTablePageLayout>
    </>
  );
};

export default Results;
